/*
  	Tejas icon font: Tjsicon
*/

@font-face {
  font-family: "Tjsicon";
  src: url("./Tjsicon.eot");
  src: url("./Tjsicon.eot?#iefix") format("embedded-opentype"),
       url("./Tjsicon.woff2") format("woff2"),
       url("./Tjsicon.woff") format("woff"),
       url("./Tjsicon.ttf") format("truetype"),
       url("./Tjsicon.svg#Tjsicon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: "Tjsicon";
	  src: url("./Tjsicon.svg#Tjsicon") format("svg");
    font-display: swap;
	}
 }
 
 [class^="tjsicon-"]:before, [class*=" tjsicon-"]:before,
 [class^="tjsicon-"]:after, [class*=" tjsicon-"]:after {   
		font-family: Tjsicon;
	 font-style: normal;
	 font-weight: normal;
   display: inline-block;
 }
 
 [class^="tjsicon-"], [class*=" tjsicon-"],
 [class^="tjsicon-"], [class*=" tjsicon-"] { 
	 font-size: inherit;
 }

.tjsicon-gift-card:before { content: "\f100"; }
.tjsicon-store:before { content: "\f101"; }
.tjsicon-pencil:before { content: "\f102"; }
.tjsicon-edit:before { content: "\f103"; }
.tjsicon-writing:before { content: "\f104"; }
.tjsicon-delete:before { content: "\f105"; }
.tjsicon-zoom-out:before { content: "\f106"; }
.tjsicon-zoom:before { content: "\f107"; }
.tjsicon-search:before { content: "\f108"; }
.tjsicon-video-call:before { content: "\f109"; }
.tjsicon-video-filled-camera-silhouette:before { content: "\f10a"; }
.tjsicon-camera-1:before { content: "\f10b"; }
.tjsicon-camera:before { content: "\f10c"; }
.tjsicon-picture:before { content: "\f10d"; }
.tjsicon-image:before { content: "\f10e"; }
.tjsicon-bill:before { content: "\f10f"; }
.tjsicon-paperclip:before { content: "\f110"; }
.tjsicon-chain:before { content: "\f111"; }
.tjsicon-more-1:before { content: "\f112"; }
.tjsicon-more:before { content: "\f113"; }
.tjsicon-alarm-clock:before { content: "\f114"; }
.tjsicon-alarm:before { content: "\f115"; }
.tjsicon-donut-chart:before { content: "\f116"; }
.tjsicon-foreign:before { content: "\f117"; }
.tjsicon-scissors:before { content: "\f118"; }
.tjsicon-print:before { content: "\f119"; }
.tjsicon-cellphone:before { content: "\f11a"; }
.tjsicon-desktop:before { content: "\f11b"; }
.tjsicon-paint-brush:before { content: "\f11c"; }
.tjsicon-bucket:before { content: "\f11d"; }
.tjsicon-edit-1:before { content: "\f11e"; }
.tjsicon-color-palette:before { content: "\f11f"; }
.tjsicon-flip:before { content: "\f120"; }
.tjsicon-crop:before { content: "\f121"; }
.tjsicon-menu:before { content: "\f122";font-weight:bold; }
.tjsicon-hard-drive:before { content: "\f123"; }
.tjsicon-floppy:before { content: "\f124";font-weight:bold; }
.tjsicon-settings:before { content: "\f125"; }
.tjsicon-music-player:before { content: "\f126";font-weight:bold; }
.tjsicon-hide:before { content: "\f127";font-weight:bold; }
.tjsicon-visibility:before { content: "\f128";font-weight:bold; }
.tjsicon-remove-1:before { content: "\f129"; font-weight:bold;}
.tjsicon-placeholder-1:before { content: "\f12a";font-weight:bold; }
.tjsicon-placeholder:before { content: "\f12b";font-weight:bold; }
.tjsicon-credit-card:before { content: "\f12c"; }
.tjsicon-dollar-currency-sign:before { content: "\f12d"; }
.tjsicon-non-commercial:before { content: "\f12e"; }
.tjsicon-dollar:before { content: "\f12f"; }
.tjsicon-refresh-1:before { content: "\f130"; }
.tjsicon-refresh-2:before { content: "\f131"; }
.tjsicon-disabled:before { content: "\f132"; }
.tjsicon-block:before { content: "\f133"; }
.tjsicon-power-variant:before { content: "\f134"; }
.tjsicon-warning:before { content: "\f135"; }
.tjsicon-danger:before { content: "\f136"; }
.tjsicon-information:before { content: "\f137"; }
.tjsicon-attention:before { content: "\f138"; }
.tjsicon-checked-1:before { content: "\f139"; }
.tjsicon-check-mark:before { content: "\f13a"; }
.tjsicon-add:before { content: "\f13b"; }
.tjsicon-x-mark:before { content: "\f13c"; }
.tjsicon-question:before { content: "\f13d"; }
.tjsicon-minus-circle:before { content: "\f13e"; }
.tjsicon-play-button:before { content: "\f13f"; }
.tjsicon-add-1:before { content: "\f140"; }
.tjsicon-play-button-1:before { content: "\f141"; }
.tjsicon-check:before { content: "\f142"; }
.tjsicon-plus-1:before { content: "\f143"; }
.tjsicon-user-1:before { content: "\f144"; }
.tjsicon-user-3:before { content: "\f145"; font-weight:bold;}
.tjsicon-add-user:before { content: "\f146"; }
.tjsicon-add-friend:before { content: "\f147"; }
.tjsicon-user:before { content: "\f148"; }
.tjsicon-user-2:before { content: "\f149"; }
.tjsicon-checked:before { content: "\f14a"; }
.tjsicon-minus-straight-horizontal-line-sign:before { content: "\f14b"; }
.tjsicon-plus:before { content: "\f14c"; }
.tjsicon-close:before { content: "\f14d"; }
.tjsicon-writing-1:before { content: "\f14e"; }
.tjsicon-file:before { content: "\f14f"; }
.tjsicon-clipboard:before { content: "\f150"; }
.tjsicon-document:before { content: "\f151"; }
.tjsicon-content-1:before { content: "\f152"; }
.tjsicon-comment:before { content: "\f153"; }
.tjsicon-message:before { content: "\f154"; }
.tjsicon-chat:before { content: "\f155"; }
.tjsicon-listing:before { content: "\f156"; }
.tjsicon-sent-mail:before { content: "\f157"; }
.tjsicon-key:before { content: "\f158"; }
.tjsicon-email:before { content: "\f159"; }
.tjsicon-call:before { content: "\f15a"; }
.tjsicon-smartphone:before { content: "\f15b"; }
.tjsicon-share:before { content: "\f15c"; }
.tjsicon-network:before { content: "\f15d"; }
.tjsicon-quote:before { content: "\f15e"; }
.tjsicon-quote-1:before { content: "\f15f"; }
.tjsicon-left-align:before { content: "\f160"; }
.tjsicon-align-center:before { content: "\f161"; }
.tjsicon-align-right:before { content: "\f162"; }
.tjsicon-justify:before { content: "\f163"; }
.tjsicon-list:before { content: "\f164"; }
.tjsicon-pin-code:before { content: "\f165"; }
.tjsicon-padlock:before { content: "\f166"; }
.tjsicon-lock:before { content: "\f167"; }
.tjsicon-update:before { content: "\f168"; }
.tjsicon-refresh-3:before { content: "\f169"; }
.tjsicon-refresh:before { content: "\f16a"; }
.tjsicon-rotate-1:before { content: "\f16b"; }
.tjsicon-rotate:before { content: "\f16c"; }
.tjsicon-label:before { content: "\f16d"; }
.tjsicon-left-arrow:before { content: "\f16e"; }
.tjsicon-right-arrow:before { content: "\f16f"; }
.tjsicon-down-arrow:before { content: "\f170"; }
.tjsicon-up-arrow:before { content: "\f171"; }
.tjsicon-next:before { content: "\f172"; }
.tjsicon-back:before { content: "\f173"; }
.tjsicon-transfer:before { content: "\f174"; }
.tjsicon-arrows:before { content: "\f175"; }
.tjsicon-sort:before { content: "\f176"; }
.tjsicon-arrow-pointing-to-down:before { content: "\f177"; }
.tjsicon-arrow-pointing-to-up:before { content: "\f178"; }
.tjsicon-arrow-pointing-to-right:before { content: "\f179"; }
.tjsicon-left-arrow-1:before { content: "\f17a"; }
.tjsicon-download:before { content: "\f17b"; }
.tjsicon-upload-1:before { content: "\f17c"; }
.tjsicon-download-1:before { content: "\f17d"; }
.tjsicon-upload:before { content: "\f17e"; }
.tjsicon-upload-2:before { content: "\f17f"; }
.tjsicon-minimize:before { content: "\f180";font-weight: bold; }
.tjsicon-expand:before { content: "\f181";font-weight: bold; }
.tjsicon-calendar-2:before { content: "\f182"; }
.tjsicon-calendar-1:before { content: "\f183"; }
.tjsicon-bell:before { content: "\f184"; }
.tjsicon-info:before { content: "\f185"; }
.tjsicon-repairing-service:before { content: "\f186"; }
.tjsicon-statistics:before { content: "\f187"; }
.tjsicon-megaphone-1:before { content: "\f188"; }
.tjsicon-customer-2:before { content: "\f189"; }
.tjsicon-customer-3:before { content: "\f18a"; }
.tjsicon-calendar-3:before { content: "\f18b"; }
.tjsicon-dashboard-1:before { content: "\f18c"; }
.tjsicon-gear:before { content: "\f18d"; }
.tjsicon-report:before { content: "\f18e"; }
.tjsicon-content:before { content: "\f18f"; }
.tjsicon-megaphone:before { content: "\f190"; }
.tjsicon-help:before { content: "\f192"; }
.tjsicon-customer:before { content: "\f191"; }
.tjsicon-calendar:before { content: "\f193"; }
.tjsicon-dashboard:before { content: "\f194";font-weight: bold;}